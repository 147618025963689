import React, { useState, useEffect, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import ReactCardFlip from 'react-card-flip'
import { Helmet } from "react-helmet";
import Card from "../components/Card.jsx"
import Fade from 'react-reveal/Fade';
import ReactHowler from 'react-howler'

import SiteUrlText from "../images/Icons/SiteUrlText.png"
import TestSiteText from "../images/Icons/TestSiteText.png"
import DealButton from "../images/Icons/DealButton.png"
import MainIcon from "../images/Icons/MainIcon.png"

import Boing from "../sounds/boing.wav"

const IndexPage = ({ data }) => {
  const [blueCards, setBlueCards] = useState([])
  const [yellowCards, setYellowCards] = useState([])
  const [blueCardInit, setBlueCardInit] = useState(false)
  const [yellowCardInit, setYellowCardInit] = useState(false)  
  const [currentBlue, setCurrentBlue] = useState(Math.floor(Math.random() * 53))
  const [currentYellow, setCurrentYellow] = useState(Math.floor(Math.random() * 53))
  const [currentBlueFlipped, setCurrentBlueFlipped] = useState(Math.floor(Math.random() * 53))
  const [currentYellowFlipped, setCurrentYellowFlipped] = useState(Math.floor(Math.random() * 53))
  const [isFlippedBlue, setIsFlippedBlue] = useState(false)
  const [isFlippedYellow, setIsFlippedYellow] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [isPlayingBoing, setIsPlayingBoing] = useState(false)
  const boingPlayer = useRef()

  const allCards = useStaticQuery(graphql`
    query MyQuery {
      allFile {
        edges {
          node {
            name
            dir
            childImageSharp {
              gatsbyImageData(width: 300,)
            }
          }
        }
      }
    }
  `)

  const filterCards = () => {
    let blueCards = allCards.allFile.edges.filter(card => card.node.dir.endsWith('BlueCards'))
    blueCards = blueCards.map(card => card.node.childImageSharp)

    let yellowCards = allCards.allFile.edges.filter(card => card.node.dir.endsWith('YellowCards'))
    yellowCards = yellowCards.map(card => card.node.childImageSharp)

    setBlueCards(blueCards)
    setYellowCards(yellowCards)
  }

  useEffect(() => {
    filterCards()
    /* eslint-disable-next-line */
  }, [])


  const getRandomCard = (deckLen) => {
    return Math.floor(Math.random() * deckLen)
  }

  const shuffleCards = () => {
    if (isFlippedBlue && isFlippedYellow) {
      setTimeout(() => {
        setCurrentBlueFlipped(getRandomCard(blueCards.length))
      }, 800)

      setTimeout(() => {
        setCurrentYellowFlipped(getRandomCard(yellowCards.length))
      }, 400)

    } else {
      setTimeout(() => {
        setCurrentBlue(getRandomCard(blueCards.length))
      }, 800)

      setTimeout(() => {
        setCurrentYellow(getRandomCard(yellowCards.length))
      }, 400)
    }
  }

  const flipYellowCard = () => {
    setIsFlippedYellow(!isFlippedYellow)
  }

  const flipBlueCard = () => {
    setIsFlippedBlue(!isFlippedBlue)
  }

  const flipCards = () => {
    flipYellowCard()
    setTimeout(() => {
      flipBlueCard()
    }, 400) 
  }

  const playSound = () => {
    setIsPlayingBoing(false)
    boingPlayer.current.seek(0)
    setIsPlayingBoing(true)
  }

  const deal = () => {
    playSound()
    
    setButtonDisabled(true)
    
    
    shuffleCards()
    
    flipCards()
  
    setTimeout(() => {
      setButtonDisabled(false)
    }, 800)
  }

  return (
    <main style={{background: 'gold'}} className="min-h-screen flex flex-col">
      <Helmet>
          <meta charSet="utf-8" />
          <title>popbangwhoosh</title>
          <meta name="description" content="popbangwhoosh test site" />
          <link rel="icon" type="image/png" href={MainIcon}/>
      </Helmet>
      <ReactHowler
        ref={boingPlayer}
        src={Boing}
        playing={isPlayingBoing}
      />
      <div className="flex justify-center">
        <a href="https://www.popbangwhoosh.com/" target="_blank" rel="noreferrer">
          <img src={MainIcon} className="h-20 md:h-32 my-10" alt="Main Icon"/>
        </a>
      </div>
      <div className={`flex justify-center min-h ${yellowCardInit && blueCardInit ? 'visible' : 'invisible'}`}>
        <Fade when={yellowCardInit && blueCardInit} delay={800}>
          <ReactCardFlip isFlipped={isFlippedYellow} infinite="true" flipDirection="horizontal">
            <Card image={yellowCards[currentYellow]?.gatsbyImageData} alt="Yellow Card" cardInit={yellowCardInit} setCardInit={setYellowCardInit} />
            <Card image={yellowCards[currentYellowFlipped]?.gatsbyImageData} alt="Yellow Card" cardInit={yellowCardInit} setCardInit={null} />
          </ReactCardFlip>
        </Fade>
        <Fade when={yellowCardInit && blueCardInit} delay={1200}>
          <ReactCardFlip isFlipped={isFlippedBlue} infinite="true" flipDirection="horizontal">
            <Card image={blueCards[currentBlue]?.gatsbyImageData} alt="Blue Card" cardInit={blueCardInit} setCardInit={setBlueCardInit}/>
            <Card image={blueCards[currentBlueFlipped]?.gatsbyImageData} alt="Blue Card" cardInit={blueCardInit} setCardInit={null} />
          </ReactCardFlip>
        </Fade>
      </div>
      <div className={`flex justify-center ${yellowCardInit && blueCardInit ? 'visible' : 'invisible'}`}>
        <Fade when={yellowCardInit && blueCardInit} delay={1600}>
          <button className="h-16 sm:h-20 md:h-32 my-10" onClick={deal} disabled={buttonDisabled}>
            <img src={DealButton} className="h-full transition transform hover:scale-110" alt="Deal Button"/>
          </button>
        </Fade>
      </div>
      <div className="flex-1 flex flex-col md:flex-row justify-center items-center md:items-end my-10">
        <a href="https://www.popbangwhoosh.com/" target="_blank" rel="noreferrer">
          <img src={TestSiteText} className="h-3 md:h-5 mx-5" alt="Test Site Text" />
        </a>
        <a href="https://www.popbangwhoosh.com/" target="_blank" rel="noreferrer">
          <img src={SiteUrlText} className="h-3 md:h-5 mx-5" alt="Site Url Text" />
        </a>
      </div>
    </main>
  )
}

export default IndexPage