import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image"
import DefaultCard from "../images/DefaultCard.png"

const Card = ({ image, alt, cardInit, setCardInit }) => {

    const shouldSetInit = cardInit === false && setCardInit;

    return (
        <div className="filter shadow-2xl rounded-2xl mx-5">
            {image
                ? <GatsbyImage image={image} alt={alt} loading="eager" onLoad={shouldSetInit ? setCardInit(true) : null} />
                : <img src={DefaultCard} className="max-w-75 w-full object-cover invisible" alt={alt} />}
        </div>
    )
}


export default Card